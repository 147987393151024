import {
  encodeUrlParams
} from "@mc/common/lib/url-params";
import Axios from "axios";
import { getRegionalizedUrl, TRACKING_API } from '@mc/common/lib/url-utils';


export const getGatekeeperDetails = (m_id, e_id) => {
  return Axios({
    method: 'get',
    url: getRegionalizedUrl(TRACKING_API) + '/gatekeeper/details',
    params: {
      v: 1,
      m_id,
      e_id,
    },
    crossdomain: true,
  })
}

export const changeWaitingEmail = (email, to_field, cc_field, bcc_field) => {
  return Axios({
    method: 'post',
    url: getRegionalizedUrl(TRACKING_API) + '/gatekeeper/send',
    data: encodeUrlParams({
      m_id: email.message_insert_id,
      e_id: email.etorch_child_guid,
      action: 'change',
      to_field,
      cc_field,
      bcc_field,
    }),
    crossdomain: true,
  })
}

export const sendWaitingEmail = (m_id, e_id) => {
  return Axios({
    method: 'post',
    url: getRegionalizedUrl(TRACKING_API) + '/gatekeeper/send',
    data: encodeUrlParams({
      m_id,
      e_id,
      action: 'send',
    }),
    crossdomain: true,
  })
}

export const stopWaitingEmail = (m_id, e_id) => {
  return Axios({
    method: 'post',
    url: getRegionalizedUrl(TRACKING_API) + '/gatekeeper/send',
    data: encodeUrlParams({
      m_id,
      e_id,
      action: 'stop',
    }),
    crossdomain: true,
  })
}

export const notifyInternalRecipients = (mId, eId) => {
  return Axios({
    method: 'post',
    url: getRegionalizedUrl(TRACKING_API) + '/gatekeeper/notifications',
    data: encodeUrlParams({
      mId,
      eId,
    }),
    crossdomain: true,
  })
}