import React, { Component } from "react"
import { Button, Col, Row, Icon } from 'antd';

import Layout from "../components/layout"

import { StateContext } from '../state/store';
import { stopWaitingEmail, notifyInternalRecipients } from "../lib/gatekeeper";
import { getGatekeeperUrlParams } from "@mc/common/lib/url-params";
import { navigate } from "gatsby";
import SEO from "../components/seo";
import FeedbackSection from "@mc/common/components/feedback";

const styles = {
  leftButton: {
    marginRight: "25px"
  },
}

class GatekeeperStopEmailPage extends Component {
  static contextType = StateContext;

  state = {
    submitting: false,
    submitted: false,
    errors: false,
    notificationOptionSelected: false,
    sendNotifications: false,
    mParam: '',
    eParam: '',
    subject: '',
    internalRecipients: ''
  }

  componentDidMount() {
    this.setState({ submitting: true });
    const params = getGatekeeperUrlParams(this.props.location);
    if (params['m_id'] === '' || params['e_id'] === '') {
      navigate('/')
    }

    this.setState({
      mParam: params['m_id'],
      eParam: params['e_id']
    });

    stopWaitingEmail(params['m_id'], params['e_id']).then((response) => {

      this.setState({
        submitting: false, submitted: true, errors: false, subject: response.data.subject,
        internalRecipients: (response.data.internalRecipients.length > 0 ? response.data.internalRecipients.map((recipient) => <li key={recipient}>{recipient}</li>) : '')
      });
    }).catch(() => {
      this.setState({ submitting: false, errors: true, });
    });
  }

  onNotificationSelection = (shouldNotify) => {
    this.setState({ sendNotifications: shouldNotify });
    if (shouldNotify) {
      notifyInternalRecipients(this.state.mParam, this.state.eParam, shouldNotify).then(() => { }).catch(() => { });
    }
    this.setState({ notificationOptionSelected: true })
  }

  renderFailure = () => {
    return (
      <Layout isModal>
        <SEO title="Stop Email Failure" />
        <Row type="flex" justify="space-around" align="middle">
          <Col span={12} style={{ textAlign: 'center', marginTop: 100, marginBottom: 100 }}>
            <h1>Unable to stop email.</h1>
            <h2 style={{ color: '#DD5454' }}>
              The email was already sent or is invalid.
          </h2>
          </Col>
        </Row>
      </Layout>
    );
  }

  renderComplete = (notificationOptionSelected) => {
    return (
      <Layout isModal>
        <SEO title="Email Held!" />
        <Row style={!notificationOptionSelected ? undefined : { display: 'none' }} type="flex" justify="space-around" align="middle">
          <Col style={{ textAlign: 'center', marginTop: 100 }}>
            <h1>Action Confirmed: Your Email Was Not Delivered To The External Recipients</h1>
            <div style={this.state.internalRecipients ? undefined : { display: 'none' }}>
              <h2 style={{ marginTop: "20px" }}>These internal recipients already received the email:</h2>
              <ul style={{ listStyleType: 'none' }}>
                {this.state.internalRecipients}
              </ul>
              <h2 style={{ marginTop: "20px", marginBottom: "10px" }}>Do You Want To Alert Internal Recipients That The Email Was Not Sent Externally?</h2>
              <Button style={styles.leftButton} size="large" type="primary" onClick={() => this.onNotificationSelection(true)} loading={this.state.submitting}>Yes</Button>
              <Button type="primary" size="large" onClick={() => this.onNotificationSelection(false)} loading={this.state.submitting}>No</Button>
              <p style={{ marginTop: "15px" }}>If you press yes, they will receive a message that says "External recipients did not receive the message and you will be resending the email."</p>
            </div>
          </Col>
        </Row>
        <Row style={notificationOptionSelected ? undefined : { display: 'none' }} type="flex" justify="space-around" align="middle">
          <Col style={{ textAlign: 'center', marginTop: 100, marginBottom: '25px' }}>
            <h1>
              Internal notifications have<span style={this.state.sendNotifications ? { display: 'none' } : undefined}> not</span> been sent
            </h1>
          </Col>
        </Row>
        <Row>
          <FeedbackSection showFeedback={this.state.internalRecipients && !this.state.notificationOptionSelected ? false : true} eId={this.state.eParam} context="gatekeeper" />
        </Row>
      </Layout>
    );
  }

  render() {
    if (this.state.submitted) {
      return this.renderComplete(this.state.notificationOptionSelected);
    }

    if (this.state.errors) {
      return this.renderFailure();
    }

    return (
      <Layout isModal>
        <SEO title="Stopping Email Now..." />
        <Row type="flex" justify="space-around" align="middle">
          <Col span={12} style={{ textAlign: 'center', marginTop: 100, marginBottom: 100 }}>
            <h1>
              <Icon type="loading" /><br />
              Stopping email now...
          </h1>
            <h2 style={{ color: '#517BBD' }}>
              Please wait.
          </h2>
          </Col>
        </Row>
      </Layout>
    );
  }
}

export default GatekeeperStopEmailPage
