import React, { Component } from "react";
import { Button, Col, Row } from 'antd';
import { StateContext } from '../../reporting/src/state/store';
import { submitFeedback } from "@mc/common/lib/feedback";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faThumbsDown } from '@fortawesome/pro-solid-svg-icons';
import styles from '@mc/common/components/feedback.module.css';

class FeedbackSection extends Component {
  static contextType = StateContext;

  state = {
    feedbackSubmitted: false,
  }

  onFeedbackSubmitted = (feedback) => {
    submitFeedback(this.props.eId, this.props.context, feedback, this.props.customerId, this.props.userEmail, this.props.rId).then(() => {
      this.setState({ feedbackSubmitted: true });
    }).catch(() => {
      this.setState({ feedbackSubmitted: true });
    });
  }

  render() {
    return (
      <Row gutter={[0, 10]} style={this.props.showFeedback ? { marginTop: 20 } : { display: 'none', }} >
        <Col span={24}>
          <div style={this.state.feedbackSubmitted ? { display: 'none' } : { textAlign: 'left', paddingBottom: 10 }} >
            <h1 style={{
              fontSize: '16px',
              fontWeight: 600,
              letterSpacing: 0,
              lineHeight: '22px',
            }}>Were these warnings helpful?</h1>
            <Col span={24}>
              <Button id='feedback-form-helpful-button' className={styles.feedbackButton} style={{ marginRight: 15 }} size="large" onClick={() => this.onFeedbackSubmitted('helpful')}>
                <FontAwesomeIcon icon={faThumbsUp}></FontAwesomeIcon>
              </Button>
              <Button id='feedback-form-unhelpful-button' className={styles.feedbackButton} size="large" onClick={() => this.onFeedbackSubmitted('unhelpful')}>
                <FontAwesomeIcon icon={faThumbsDown}></FontAwesomeIcon>
              </Button>
            </Col>
          </div>
          <div style={this.state.feedbackSubmitted ? { textAlign: 'left' } : { display: 'none' }}>
            <h1 style={{
              fontSize: '16px',
              fontWeight: 600,
              letterSpacing: 0,
              lineHeight: '22px',
            }}>Thanks!</h1>
          </div>
        </Col>
      </ Row >
    );
  }
}

export default FeedbackSection
