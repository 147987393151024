
import {
    encodeUrlParams
} from "@mc/common/lib/url-params";
import { getRegionalizedUrl, TRACKING_API } from './url-utils';

import Axios from "axios";

export const submitFeedback = (eId, context, feedback, customerId = null, userEmail = null, rId = -1) => {
    return Axios({
        method: 'post',
        url: getRegionalizedUrl(TRACKING_API) + '/user/feedback',
        data: encodeUrlParams({
            eId,
            rId,
            context,
            feedback,
            customerId,
            userEmail
        }),
        crossdomain: true,
    })
}